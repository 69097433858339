import Header from '../../Components/Common/Header';
import styles from './styles.module.css';
export const PrivacyPolicy = () => {
  return (
    <>
      <Header forceActive />

      <div id="container" className={styles.docContainer}>
        <h1 className={styles.frndcommunity}>

            Cold Brew Tech Privacy Policy

        </h1>
        <p>Last updated: 12 March  2025</p>
        <p>
          At Cold Brew Private Limited ("FRND"), your privacy is our priority.
          This Privacy Policy explains how we collect, use, and share your data
          when you use the FRND mobile app (“App”) and website (“Website”),
          together called the “Platform.”
        </p>
        <ul>
          <li>
            <p>
              <strong>Who We Are:</strong> References to "we," "us," or "our"
              mean Cold Brew Private Limited and/or the Platform. "You" or
              "user" refers to anyone using our Platform.
            </p>
          </li>
          <li>
            <p>
              <strong>Your Consent:</strong> By using the Platform, you agree to
              this Privacy Policy and consent to how we handle your personal
              information (<strong>“Personal Information”</strong>). If you
              don’t agree, please don’t use the Platform.
            </p>
          </li>
          <li>
            <p>
              <strong>Using Your Information:</strong> We use and share your
              information only as described in this Privacy Policy. We won’t
              disclose your information without your consent, except as
              explained here.
            </p>
          </li>
        </ul>
        <p>
          This Privacy Policy works alongside our Terms of Service. If there is
          a conflict, this Privacy Policy takes precedence. Any undefined terms
          here have the same meaning as in the Terms of Service.
        </p>
        <p>
          <strong>HOW DO WE USE YOUR INFORMATION</strong>
        </p>
        <p>
          <strong>
            The following table lists the information we collect from you and
            how we use it:
          </strong>
        </p>
        <table>
          <tr>
            <th>
              <p>Information collected</p>
            </th>
            <th>
              <p>Use of information</p>
            </th>
          </tr>
          <tr>
            <td>
              <p>
                <em>Account information:</em> User ID, mobile number, password,
                gender, voice recordings, IP address, and approximate age range
              </p>
              <p>
                <em>Profile information:</em> Username, photo, and birth year.
              </p>
            </td>
            <td rowspan="6">
              <ul>
                <li>
                  <p>To create and manage your account.</p>
                </li>
                <li>
                  <p>To inform you about updates or changes.</p>
                </li>
                <li>
                  <p>To assist with communication and user support.</p>
                </li>
                <li>
                  <p>To enforce terms and protect rights.</p>
                </li>
                <li>
                  <p>
                    To create new features and improve existing services based
                    on feedback.
                  </p>
                </li>
                <li>
                  <p>
                    To provide personalized content based on language and
                    location.
                  </p>
                </li>
                <li>
                  <p>
                    To manage the platform, troubleshoot issues, and enhance
                    security.
                  </p>
                </li>
                <li>
                  <p>For demographic analysis.</p>
                </li>
                <li>
                  <p>To better understand user engagement.</p>
                </li>
                <li>
                  <p>To analyze traffic and improve platform features.</p>
                </li>
                <li>
                  <p>To evaluate advertising and marketing efforts.</p>
                </li>
                <li>
                  <p>To enhance navigation and improve platform performance.</p>
                </li>
                <li>
                  <p>
                    To personalize your experience and deliver targeted ads.
                  </p>
                </li>
                <li>
                  <p>To analyze search trends.</p>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <em>Content shared by you:</em> Personal information, images,
                quotes, and other shared content (e.g., via training rooms,
                games, or radio).
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <em>Third party information:</em> Data from business partners,
                analytics providers, and subcontractors.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <em>Log data:</em> Technical information (e.g., IP address,
                device ID, browsing history, metadata, and communication
                details).
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <em>Cookies:</em> Data from cookies stored on your device.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <em>Surveys and Search Activity:</em> Personal information
                provided during surveys and search history.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <em>Verification and Contact:</em> Phone number (for OTP
                verification) and optionally your contacts (with consent).
              </p>
            </td>
            <td>
              <ul>
                <li>
                  <p>To confirm identity and secure accounts.</p>
                </li>
                <li>
                  <p>To enable features like "Invite Users."</p>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <em>Location Data:</em> GPS or IP-based location data.
              </p>
            </td>
            <td>
              <ul>
                <li>
                  <p>To provide location-based features and content.</p>
                </li>
                <li>
                  <p>To enhance security and detect fraud.</p>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <em>Device Data:</em> Information about operating system,
                browser, storage, battery, and hardware, as well as access to
                your gallery (with consent).
              </p>
            </td>
            <td>
              <ul>
                <li>
                  <p>To optimize the platform for your device.</p>
                </li>
                <li>
                  <p>To enable sharing and verify identity.</p>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <em>Interactions:</em> Chat and conversation details, including
                timestamps and user interactions.
              </p>
            </td>
            <td>
              <ul>
                <li>
                  <p>To improve chat suggestions and refine recommendations.</p>
                </li>
                <li>
                  <p>To evaluate marketing efforts.</p>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <em>Games, Contests, and Purchases:</em> Contest participation
                details, financial information for direct purchases.
              </p>
            </td>
            <td>
              <ul>
                <li>
                  <p>To enable participation and distribute prizes.</p>
                </li>
                <li>
                  <p>To manage payments and transaction logs.</p>
                </li>
              </ul>
            </td>
          </tr>
        </table>
        <h2>YOUR RIGHTS</h2>
        <p>
          <strong>Removing Content or Deleting Your Account:</strong> You can
          delete content from your profile or deactivate/delete your account
          anytime. However, we will still have records of your activities and
          account on our platform.
        </p>
        <p>
          <strong>Updating Your Information:</strong> You can update, change,
          add, or delete your personal information by logging into your profile
          only once. You can also opt out of unwanted emails by following the
          instructions in the message. System-generated emails will still be
          sent unless you delete your account.
        </p>
        <p>
          <strong>Accessing and Correcting Data:</strong> To access the personal
          or sensitive data you have shared with us, email us at care@frnd.app.
          If you find errors or missing information, let us know, and we’ll
          correct or update it where possible.
        </p>
        <p>
          <strong>Declining or Withdrawing Consent:</strong> You can choose not
          to share requested information or withdraw your consent for us to use
          your data by emailing us at care@frnd.app. Please allow 30 days for
          processing. Remember, declining or withdrawing consent may limit
          access to some products or services.
        </p>
        <h3>HOW YOUR INFORMATION IS SHARED</h3>
        <p>
          <strong>Information Visible to Other Users:</strong>
        </p>
        <ul>
          <li>
            <p>
              Parts of your profile, like your username, age, gender, and
              location, are visible to other users.
            </p>
          </li>
          <li>
            <p>
              When you join public features like training rooms, Dum Sharas,
              LoveSkool, FRND making rooms, FRND Radio, and other public
              features, your activities and shared content can be seen by
              others.
            </p>
          </li>
          <li>
            <p>
              In video or audio calls, other participants can see and hear your
              video feed, voice, and any other audio you share.
            </p>
          </li>
          <li>
            <p>You can manage who contacts you by:</p>
            <ul>
              <li>
                <p>Choosing who can contact you.</p>
              </li>
              <li>
                <p>Blocking unwanted users.</p>
              </li>
              <li>
                <p>Reporting inappropriate messages.</p>
              </li>
            </ul>
          </li>
        </ul>
        <h3>How We Share Data Within Our Group</h3>
        <p>We may share your data with affiliated companies for:</p>
        <ul>
          <li>
            <p>
              <i>Safety:</i> If you are banned on one platform, the ban applies
              across all group platforms.
            </p>
          </li>
          <li>
            <p>
              <i>Operations:</i> Helping with tasks like hosting, customer
              support, marketing, analytics, payments, and security.
            </p>
          </li>
          <li>
            <p>
              <i>Insights:</i> Personalizing your experience and improving
              services across platforms (where allowed by law).
            </p>
          </li>
          <li>
            <p>
              <i>Business Needs:</i> Audits, reporting, and other legitimate
              business purposes.
            </p>
          </li>
        </ul>
        <h3>Information You Choose to Share</h3>
        <p>
          When you share content on our platform, or when you share content from
          our platform off the platform (for example, on Facebook or WhatsApp),
          you decide who can see it. We are not responsible for how others use
          this shared information.
        </p>
        <h3>Sharing with Third Parties</h3>
        <p>We may share your data with third parties in the following ways:</p>
        <ul>
          <li>
            <p>
              <i>With Business Partners:</i> To help deliver and improve our
              services (for example, hosting, advertising, payments, and
              security). Advertisers may receive aggregated data, but no
              personally identifiable information.
            </p>
          </li>
          <li>
            <p>
              <i>With Authorities:</i> If required by law or to address fraud,
              safety, or security concerns.
            </p>
          </li>
          <li>
            <p>
              <i>During Business Changes:</i> If the company is acquired,
              merged, or reorganized, your data may be transferred. We will
              notify you in advance and give you the option to delete your
              account.
            </p>
          </li>
        </ul>
        <h3>Changes to This Policy</h3>
        <p>
          We may update this Privacy Policy from time to time. Please check this
          page regularly to stay updated on any changes. If we make any major
          changes that affect you, we will ask for your consent.
        </p>
        <h3>Data Retention</h3>
        <p>
          We only keep your sensitive personal information for as long as needed
          for legal or business purposes. Other personal information is kept
          only for as long as necessary to provide our services or as required
          by law. If you request, we will delete your information, unless we are
          required to keep it for legal reasons. Sensitive personal information
          includes things like passwords and any other data defined as sensitive
          under the law.
        </p>
        <p>
          We may also need to keep your information for legal, security, or
          investigation purposes, such as complying with court orders or
          addressing reports of abuse or policy violations.
        </p>
        <h3>Third-Party Links</h3>
        <p>
          Our platform may contain links to third-party websites or apps. If you
          click on these links, please be aware that these sites have their own
          privacy policies. We are not responsible for their practices, so we
          recommend reviewing their policies before sharing any personal
          information with them.
        </p>
        <h2>THIRD-PARTY EMBEDS</h2>
        <h3>What Are Third-Party Embeds?</h3>
        <p>
          Some content on our platform is hosted by other companies, such as
          YouTube videos, Giphy gifs, tweets from X, or SoundCloud audio. When
          you view these, data is sent to those sites just as if you were
          visiting them directly.
        </p>
        <h3>Sharing Personal Information with Third-Party Embeds</h3>
        <p>
          Some of these third-party services may ask for your personal
          information, like your email. While we work to keep bad actors off the
          platform, we cannot control how third parties use your information.
          Their actions are not covered by this Privacy Policy. Please be
          careful when entering your personal information on embedded forms.
          Make sure you understand who is asking for your data and how they will
          use it. We recommend not sharing personal information through embedded
          forms.
        </p>
        <h3>Privacy Concerns with Third-Party Embeds</h3>
        <p>
          We do not control how third parties collect or use your data. These
          third-party services are governed by their own privacy policies, not
          by ours.
        </p>
        <h3>Creating Your Own Third-Party Embed</h3>
        If you add a form that collects personal information, you must include a
        clear link to your privacy policy that explains how the data will be
        used. Failure to do so may result in us disabling your post or taking
        action against your account.
        <h3>SECURITY PRACTICES</h3>
        <p>
          We have security measures in place to protect the information we
          collect. If you have a username and password to access the platform,
          you are responsible for keeping these details safe. Please do not
          share your password with anyone.
        </p>

        <h3>COMMUNICATIONS FROM US</h3>
        <p>
          Sometimes we will send you important notifications about the platform,
          such as during maintenance or for security updates. These messages are
          necessary for your account and cannot be turned off.
        </p>
        <h3>WHERE WE STORE YOUR PERSONAL INFORMATION</h3>
        <p>
          We store your data with Amazon Web Services (AWS) and Google Cloud,
          both of which have security measures to protect your information.
          Their privacy policies can be found on their websites.
        </p>
        <h3>DISCLAIMER</h3>
        <p>
          While we take all necessary steps to protect your personal
          information, please note that transmitting data over the internet is
          never fully secure. We cannot guarantee the security of your
          information during transmission, but once we receive it, we apply
          strong security measures to protect it.
        </p>
     <div className={styles.aligncenter}>
     <h3 > COOKIE POLICY</h3>
     </div>
        <p>Last updated:  12 March 2025</p>
        <p>
          This policy offers further details on the cookies and tracking
          technologies we use and should be read alongside our{" "}
          Privacy Policy. Capitalized terms used in this Cookie
          Policy but not defined here will have the meanings assigned to them in
          the Terms of Service.
        </p>
        <h4> What are cookies, pixels, and local storage?</h4>
        <p>
          Cookies are small text files that are placed on your device or
          accessed through your web browser. Typically, a cookie includes the
          domain name (the internet address) it originated from, its "lifetime"
          (i.e., expiration date), and a randomly generated unique identifier or
          similar code. Additionally, a cookie may store information about your
          device, such as your user settings, browsing history, and activities
          while using our services.
        </p>
        <p>
          A pixel is a tiny piece of code embedded in a webpage or email
          notification. It allows us to track your interaction with specific
          content, helping us assess and enhance the performance of our Platform
          and tailor your experience.
        </p>
        <p>
          Local storage is a commonly used technology that enables websites or
          apps to save information directly on your device. We use it to
          personalize your experience by displaying content based on your
          previous interactions with our Platform.
        </p>
        <p>
          <strong>What types of cookies do we use?</strong>
        </p>
        <p>Cookies can have varying durations depending on their type:</p>
        <ul>
          <li>
            <p>
              <strong>Session Cookies:</strong> These cookies are temporary and
              last only for the duration of your browsing session. They expire
              once you close your browser or application.
            </p>
          </li>
          <li>
            <p>
              <strong>Persistent Cookies:</strong> These cookies remain on your
              device even after you close your browser. They are used to
              remember your preferences and settings across multiple visits to
              the Platform.
            </p>
          </li>
        </ul>
        <p>
          Certain pages on our Platform may utilize pixel tags (also referred to
          as clear gifs) to gather information, which may then be shared with
          third parties that support our marketing activities and Platform
          enhancement. For example, data about visitor interactions on our
          Platform may be shared with our advertising partners to optimize the
          targeting of banner ads. While this data is not personally
          identifiable on its own, it may be linked to your personal
          information.
        </p>
        <p>
          <strong>Do we use Third Party Cookies?</strong>
        </p>
        <p>
          We collaborate with various suppliers who may place cookies on your
          device to provide their services. When visiting our Platform, you may
          encounter cookies from third-party sites or domains. We aim to
          identify these cookies in advance, allowing you to choose whether to
          accept them. For more details, you can visit the respective third
          party's website.
        </p>
        <p>
          <strong>Why do we use these technologies?</strong>
        </p>
        <ul>
          <li>
            <p>
              We use these technologies to deliver relevant content, enhance
              user experience, and protect our users.
            </p>
          </li>
          <li>
            <p>
              These technologies help provide faster, easier services and enable
              functionality across the Platform.
            </p>
          </li>
          <li>
            <p>
              They may retain authentication details, store your language
              preference, and support location-based services requiring your
              location.
            </p>
          </li>
          <li>
            <p>
              We use these technologies to track how you use our Platform,
              including frequently visited pages and error messages.
            </p>
          </li>
          <li>
            <p>
              They also help gather data on the total number of visitors to the
              Platform.
            </p>
          </li>
          <li>
            <p>
              In collaboration with advertising partners, we use these
              technologies to improve, understand, and target the ads you see.
            </p>
          </li>
        </ul>
        <p>
          <strong>Cookies used on the Platform</strong>
        </p>
        <div>
          <table className={styles.privacytable}>
            <tr>
              <th>
                <p>Type of Cookie</p>
              </th>
              <th>
                <p>What do they do?</p>
              </th>
              <th>
                <p>Do these cookies collect my personal data / identify me?</p>
              </th>
            </tr>
            <tr>
              <td>
                <p>Necessary</p>
              </td>
              <td>
                <ul>
                  <li>
                    <p>
                      Essential for the Platform’s functionality (for example,
                      log-in, security, fraud prevention).
                    </p>
                  </li>
                  <li>
                    <p>
                      Without them, you cannot navigate the Platform properly.
                    </p>
                  </li>
                </ul>
              </td>
              <td>
                <p>
                  These cookies do not recognize you as a specific individual.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Performance</p>
              </td>
              <td>
                <ul>
                  <li>
                    <p>
                      Help analyze how the Platform is used (for example, time
                      spent, areas visited, errors encountered).
                    </p>
                  </li>
                  <li>
                    <p>Improve Platform performance based on usage insights.</p>
                  </li>
                </ul>
              </td>
              <td>
                <p>
                  These cookies do not identify you as an individual. All data
                  is collected and reported in an aggregated, anonymous manner.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Functionality</p>
              </td>
              <td>
                <ul>
                  <li>
                    <p>
                      Remember your preferences (for example, language,
                      settings, login status).
                    </p>
                  </li>
                  <li>
                    <p>Tailor the Platform’s services to your needs.</p>
                  </li>
                </ul>
                <p>
                  If you decide not to accept these cookies, it could affect how
                  the Platform performs and functions, potentially restricting
                  your access to some content.
                </p>
              </td>
              <td>
                <p>
                  May collect identifiable information like your username or
                  profile picture. We are transparent about this data
                  collection.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Targeting / advertising</p>
              </td>
              <td>
                <ul>
                  <li>
                    <p>Deliver content and ads based on your interests.</p>
                  </li>
                  <li>
                    <p>
                      Limit ad frequency and measure advertising effectiveness.
                    </p>
                  </li>
                  <li>
                    <p>May track pages or websites you visit.</p>
                  </li>
                </ul>
                <p>
                  We may use these cookies to track the pages or websites you've
                  visited and share this information with third parties, such as
                  advertisers and our partners.
                </p>
              </td>
              <td>
                <p>
                  Most of these cookies track users through their IP address,
                  which may result in the collection of some personally
                  identifiable information.
                </p>
              </td>
            </tr>
          </table>
        </div>
        <p>
          <strong>Where are these technologies used?</strong>
        </p>
        <p>
          We use these technologies on our Platform and on other websites that
          have integrated our services, including our advertising and platform
          partners. Third parties may also utilize these technologies, such as
          when you engage with their content within our Platform—like clicking a
          link or streaming media from a third-party service—or to assist in
          delivering advertisements both on and off our Platform.
        </p>
        <p>
          <strong>How can I control Cookies?</strong>
        </p>
        <p>
          Most web browsers are set to accept cookies by default. You can modify
          these settings to block cookies or receive notifications when cookies
          are being sent to your device.
        </p>
        <p>
          Disabling cookies may affect your experience on the Platform. For
          example, you may lose access to certain features or not receive
          personalized content.
        </p>
        <p>
          If you access the Platform from multiple devices (e.g., computer,
          smartphone, tablet), you'll need to adjust the cookie settings on each
          device's browser to match your preferences.
        </p>
        <p>
          <strong>Cookies that have been set in the past</strong>
        </p>
        <p>
          If you disable one or more cookies, we may still use the data
          collected from those cookies before your preference was changed.
          However, we will cease using the disabled cookies to gather any
          further information.
        </p>
        <p>
          <strong>Changes to this Cookie Policy</strong>
        </p>
        <p>
          We may update this Cookie Policy from time to time to reflect changes
          in our Platform or services. If there are any significant updates
          regarding how we collect, use, or share information through cookies,
          we will post the changes here and update the ‘Last Updated’ date at
          the top of the policy.
        </p>
      </div>
    </>
  );
};
