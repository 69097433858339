import Header from "../../Components/Common/Header";
import styles from "./styles.module.css";
export const CommunityGuidelines = () => {
  return (
    <>
      <Header forceActive />
      <div id="container" className={styles.docContainer}>
        <h1 className={styles.frndcommunity}>FRND Community Guidelines</h1>

        <p>
          Our Community Guidelines (“ <strong>Community Guidelines </strong>”)
          set the standards for respectful and responsible engagement on FRND,
          helping ensure a safe environment for all members. They should be read
          alongside the FRND Privacy Policy and Terms of Use, together called
          the “ <strong>Platform Policies. </strong>” Any capitalized terms not
          defined here will have the same meaning as in the Terms of Use.
        </p>

        <h3>Compliance with applicable laws</h3>
        <p>
          All content on the Platform must follow applicable laws, including the
          Bharatiya Nyaya Sanhita, 2023, the Information Technology Act, 2000,
          and the Consumer Protection Act, 2019, along with their rules and
          amendments.
        </p>

        <h3>Platform Safety</h3>
        <p>
          We use various tools to keep FRND safe, including advanced AI that
          detects harmful content in real-time. Our team of moderators works
          24/7 to review flagged content and user reports, responding quickly to
          any issues.
        </p>
        <p>
          While we believe in giving users opportunities to learn from mistakes,
          certain violations warrant immediate and permanent action. Critical
          offenses, including illegal activities or threats of harm, may result
          in immediate permanent suspension. Similarly, accounts showing
          consistent patterns of Community Guidelines violations despite
          corrective measures may face permanent restrictions.
        </p>
        <p>
          In most cases, users can return to the Platform after temporary
          suspensions, provided they demonstrate improved behaviour. However, we
          maintain a strict stance against serious misconduct to protect the
          safety and integrity of our community.
        </p>
        <h3>Your Content and Platform Rights</h3>
        <p>
          When you share content on our Platform, you keep ownership of your
          work. However, by posting, you give us a global, non-exclusive,
          royalty-free license to use it. This allows us to display, modify,
          distribute, and use your content to improve our Services, including
          marketing, translations, and sharing on affiliated platforms.
        </p>
        <p>
          This license also lets us create derivative works and transfer the
          rights as needed. You waive any moral rights or need for credit
          attribution in how we use your content. We will follow our Privacy
          Policy and Community Guidelines when exercising any rights in relation
          to the content.
        </p>
        <p>
          You are responsible for ensuring you have the rights to any content
          you share, including permissions for third-party materials like music
          or videos.
        </p>
        <p>
          We may remove or modify any content that violates the Platform
          Policies or others' rights
        </p>
        <h3>Platform Ownership and Intellectual Property</h3>
        <p>
          The Platform exclusively owns all its content, including visuals,
          features, design elements, software, and other components (excluding
          user and third-party content). This content is protected by all
          applicable intellectual property rights worldwide, including
          copyrights, trademarks, and patents.
        </p>
        <p>
          Using the Platform does not give you ownership of its content. You
          cannot modify, reproduce, distribute, or publicly display Platform
          content, other users' content, or third-party content unless you have
          explicit permission from the owner.
        </p>

        <h3>Feedback and Suggestions</h3>
        <p>
          The Platform values your feedback and uses it to improve our Services.
          When you share ideas, suggestions, or comments (“Feedback”), you agree
          to the following terms:
        </p>
        <ul>
          <li>
            <p>
              Your Feedback must not include confidential or proprietary
              information belonging to others.
            </p>
          </li>
          <li>
            <p>
              The Platform is not obligated to keep your Feedback confidential
              and may already be working on similar improvements.
            </p>
          </li>
          <li>
            <p>
              By sharing Feedback, you give the Platform full ownership and the
              right to use it in any way, without compensation or obligations to
              you, even if you marked it as confidential.
            </p>
          </li>
        </ul>

        <h3>Reporting and Enforcement</h3>
        <p>
          We address Community Guidelines violations in a manner, starting with
          content removal and escalating to temporary or permanent account bans
          based on the severity and frequency of issues.
        </p>

        <p>
          To report a violation, email our Grievance Officer at{" "}
          <a href="mailto:care@FRND.app">care@FRND.app</a>. We review all
          reports thoroughly and take action within legally required timelines.
          Misusing the reporting system to harass others can lead to immediate
          account suspension.
        </p>
        <p>
          We also work with law enforcement when there is a real risk of harm,
          public safety threats, or other legal violations.
        </p>

        <h3>Platform Payments and Virtual Items</h3>
        <p>
          The Platform offers premium Services and features that require payment
          for access. This includes certain virtual items, and platform-specific
          coins provided on the Platform ("Virtual Items"). The Virtual Items
          represent gift vouchers and subscription elements within our Platform
          ecosystem.
        </p>
        <p>
          When making purchases, you authorise us or our designated payment
          processors to charge your selected payment method, including
          applicable taxes. All transactions are processed through secure
          third-party payment systems operating under their respective terms and
          conditions.
        </p>
        <p>
          When you purchase or use paid features, including Virtual Items, you
          agree to the following terms:
        </p>
        <ul>
          <li>
            <p>All purchases are final and non-refundable once completed.</p>
          </li>
          <li>
            <p>
              Virtual Items represent limited-use licenses, not ownership
              rights.
            </p>
          </li>
          <li>
            <p>
              Users must use only authorised payment channels; any attempt to
              circumvent payment systems is prohibited.
            </p>
          </li>
          <li>
            <p>
              The Platform reserves the right to modify or restrict access to
              paid features, including Virtual Items, for violations of Platform
              Policies.
            </p>
          </li>
          <li>
            <p>
              Purchases once made, or Gifts sent using Virtual Items, cannot be
              reversed or refunded.
            </p>
          </li>
        </ul>

        <h3>Violence Dangerous and Harmful Content</h3>
        <p>
          We do not allow content that promotes violence, dangerous activities,
          or harmful behaviour.{" "}
        </p>
        <p>This includes:</p>
        <ul>
          <li>
            <p>
              Graphic violence, display or discussion of gore or bodily
              conditions.
            </p>
          </li>
          <li>
            <p>
              Cruelty, or mistreatment of people or animals, including violent
              imagery or content glorifying suffering.
            </p>
          </li>
          <li>
            <p>
              Support for terrorists, extremist groups, or criminal
              organisations, such as praising their actions or recruiting for
              them.
            </p>
          </li>
          <li>
            <p>
              Promotion of organised crime or encouraging involvement in violent
              groups.
            </p>
          </li>
          <li>
            <p>
              Instructions for making weapons, explosives, or dangerous
              materials.
            </p>
          </li>
          <li>
            <p>Content promoting criminal and illegal activities.</p>
          </li>
        </ul>

        <h3>Sexual Content and Exploitation</h3>
        <p>
          FRND has strict policies against sexual content to maintain user
          safety and platform integrity. All forms of sexual content are
          prohibited. The following content is prohibited on the Platform:
        </p>
        <p>The following content is prohibited on the Platform:</p>
        <ul>
          <li>
            <p>Pornographic, sexually explicit, or suggestive material.</p>
          </li>
          <li>
            <p>
              Sexual poses, suggestive dancing, or simulation of sexual
              activity.
            </p>
          </li>
          <li>
            <p>
              Using intimate images or recordings to harass, threaten, or
              exploit others, including sharing intimate content without
              consent.
            </p>
          </li>
          <li>
            <p>
              Content that invades bodily privacy or shares personal information
              for exploitation.
            </p>
          </li>
          <li>
            <p>
              Content harmful to minors, including any material related to
              paedophilia, child sexual abuse, or child exploitation, whether
              visual, audio, or text.
            </p>
          </li>
          <li>
            <p>
              Content that depicts, promotes, or glorifies non-consensual sexual
              activities, rape, sexual assault, molestation, or any form of
              sexual violence.
            </p>
          </li>
          <li>
            <p>
              Content featuring revealing clothing, partial nudity, or any
              attempts to circumvent the restrictions contained in these
              Community Guidelines through implied sexual content or innuendo.
            </p>
          </li>
        </ul>

        <h3>Animal Protection and Safety</h3>
        <p>
          We prohibit any content involving animal harm, exploitation, or abuse,
          including acts depicting animal cruelty, illegal trading particularly
          of endangered species, or content that sexualises animals in any way.
          Sharing of such content may lead to account restriction and may be
          reported to relevant authorities.
        </p>

        <h3>Combating Harassment</h3>
        <p>
          The Platform maintains strict policies against harassment and bullying
          to ensure platform safety. We encourage users to report content that
          harasses, degrades, or shames others rather than engaging with it
          directly.
        </p>

        <p>The Platform explicitly prohibits:</p>
        <ul>
          <li>
            <p>
              Using abusive language, curse words, altered or manipulated
              images, and malicious recordings meant to cause harm or distress.
            </p>
          </li>
          <li>
            <p>
              Discriminating against others based on gender, race, ethnicity,
              caste, colour, disabilities, religion, or sexual preferences.
            </p>
          </li>
          <li>
            <p>
              Extortion or blackmail of any individual, whether generally or
              based on protected characteristics.
            </p>
          </li>
          <li>
            <p>
              Trying to contact users through alternate accounts after being
              blocked.
            </p>
          </li>
          <li>
            <p>
              Sharing personal images or information without consent, especially
              to harass, distress, or endanger someone.
            </p>
          </li>
          <li>
            <p>
              Spreading false information to cause emotional, physical, or
              mental harm or to achieve financial gain.
            </p>
          </li>
        </ul>

        <h3>Hate Speech and Propaganda</h3>
        <p>
          We do not tolerate any content that promotes discrimination, violence
          or hatred against any individual or group. This includes any material
          intended to foster division within communities or target people based
          on their identity.
        </p>

        <p>The Platform explicitly prohibits content that:</p>
        <ul>
          <li>
            <p>
              Promotes discrimination, violence, or hatred based on protected
              characteristics including religion, race, caste, ethnicity,
              nationality, disability, disease, gender, sexual orientation, or
              gender identity.
            </p>
          </li>
          <li>
            <p>
              Intimidates, demeans, or portrays specific communities or
              protected groups as inferior, including through hate propaganda or
              negative stereotypes.
            </p>
          </li>
          <li>
            <p>
              Attempts to justify violence or promote enmity against any group,
              particularly content meant to incite religious or caste-based
              conflict.
            </p>
          </li>
          <li>
            <p>
              Makes defamatory statements or is otherwise false, untrue, or
              misleading in nature.
            </p>
          </li>
        </ul>

        <p>
          The Platform specifically prohibits imagery or symbols of hate groups
          and hateful ideologies. We do not allow disrespectful comments
          targeting religious beliefs, including insults to Gods, religious
          deities, symbols, or emblems of any faith.
        </p>

        <h3>Abuse and Mental Health</h3>
        <p>
          Our Platform strictly prohibits content showing any form of abuse or
          maltreatment of individuals, whether children or adults. This includes
          physical, mental, sexual, or psychological abuse. We do not allow
          content that glorifies or provides instructions for self-harm,
          promotes suicide, or displays abusive behaviours.
        </p>

        <p>
          We encourage content focused on recovery, healing, and mental health
          awareness. Users can share personal experiences and coping strategies
          that help others. However, these stories should not include triggering
          details or identify individuals without their consent.
        </p>

        <h3>Emergency Services Access</h3>
        <p>
          Our communication features are designed for social connection and
          entertainment purposes only. Please understand that our Services are
          not meant to replace regular phone services, particularly in cases of
          emergency situations. You must not use our Platform to contact
          emergency services such as hospitals, police, medical centres, or
          other emergency response teams. For your safety, please make sure you
          have other ways to make emergency calls readily available.
        </p>

        <p>
          We are not responsible for any issues that may arise from attempting
          to use our Platform for emergency communications, or from being unable
          to make emergency calls through our Services.
        </p>

        <h3>Privacy and Personal Data Protection</h3>
        <p>
          Our Platform safeguards user privacy by preventing screenshots and
          screen recordings during video calls. Additionally, we provide
          AI-generated avatars for profile pictures, enabling users to express
          themselves while maintaining anonymity.
        </p>

        <p>
          The misuse or unauthorized sharing of personal data, including contact
          details, passwords, addresses, financial information, biometric data,
          government IDs, health information, or any private or intimate
          content, is strictly prohibited. Sharing such data without explicit
          consent is a serious violation.
        </p>

        <p>
          Users must respect the privacy of others by not posting or sharing
          sensitive information, recording or distributing interactions without
          consent, or impersonating others. Threatening to expose personal data
          or misusing someone’s private content is also strictly forbidden.
        </p>

        <p>
          For your safety, avoid sharing personal contact information during
          public calls. The Platform prohibits attempts to gather personal
          details, including login credentials, real names, or social media
          profiles. Sharing links to external platforms where such information
          might be accessed is not allowed. If you encounter inappropriate
          content or behavior, please end the interaction and report it through
          our reporting system. These Community Guidelines apply equally to
          private and public interactions.
        </p>

        <p>
          Please also note that content shared on the Platform is not end-to-end
          encrypted. We maintain access to this content in the manner specified
          in the Privacy Policy.
        </p>

        <h3>Preventing False Information</h3>
        <p>
          The Platform prohibits the deliberate sharing of false, defamatory, or
          misleading content, whether in public or private communications. This
          includes any material created to deceive users or known to be untrue.
          We do not tolerate fabricated news, hoaxes, or manipulated
          media—whether text, audio, or video—that could harm individuals,
          disrupt democratic processes, enable fraud, or incite conflict.
        </p>

        <p>
          We take action against content that uses false claims to damage
          reputations or influence financial or political outcomes. While satire
          and parody are welcome, they must be clearly identifiable and not
          intended to mislead or deceive. Users are encouraged to verify their
          sources and present information truthfully.
        </p>

        <h3>Spam and Misleading Content</h3>
        <p>
          Content that misleads about its source or purpose is not allowed,
          including deceptive promotions, and content containing malicious links
          or phishing attempts. All promotional content must be transparent and
          honest. Misleading titles, or descriptions that do not accurately
          represent content are prohibited, so are links to malware, scams, or
          misrepresented content.
        </p>

        <p>
          To maintain Platform quality and user experience, we remove spam
          content. Accounts with repeated violations may face restrictions or
          suspension.
        </p>

        <h3>Platform Integrity and Fair Usage</h3>
        <p>
          Our Platform is designed to promote fair and honest engagement for all
          users. We maintain strict standards to ensure all users can
          participate equally, without manipulation or unfair advantages.
        </p>

        <p>The following activities are not allowed:</p>
        <ul>
          <li>
            <p>
              Using unauthorized tools, programs, or modifications to alter
              Platform functionality or gain an unfair advantage.
            </p>
          </li>
          <li>
            <p>
              Employing automated tools, bots, or exploiting technical
              vulnerabilities.
            </p>
          </li>
          <li>
            <p>
              Accessing the Platform via modified or tampered devices, such as
              rooted or jailbroken devices.
            </p>
          </li>
          <li>
            <p>
              Creating multiple accounts, manipulating Platform metrics, or
              accessing other users' accounts without authorization.
            </p>
          </li>
          <li>
            <p>
              Developing tools that disrupt Platform operations or extract
              information without authorization.
            </p>
          </li>
        </ul>

        <p>
          Users found engaging in these behaviours may face immediate account
          suspension or termination.
        </p>

        <h3>Prohibited/Regulated Items and Services</h3>
        <p>
          Trading or engaging in commercial activities involving regulated or
          restricted items is strictly prohibited. This includes:
        </p>
        <ul>
          <li>
            <p>Alcohol or tobacco products</p>
          </li>
          <li>
            <p>Recreational drugs</p>
          </li>
          <li>
            <p>Substances requiring special permits or licenses</p>
          </li>
          <li>
            <p>Pharmaceutical products</p>
          </li>
          <li>
            <p>Sexual services or related solicitations</p>
          </li>
          <li>
            <p>Any goods or services requiring government authorization</p>
          </li>
        </ul>

        <p>
          Users are also prohibited from engaging in or sharing content
          depicting substance abuse or encouraging its use. This includes
          content that promotes the use of recreational drugs, alcohol, tobacco
          or other harmful substances.
        </p>

        <h3>Financial Misconduct</h3>
        <p>
          Activities that involve fraudulent, illegal, or unethical financial
          practices are strictly prohibited. This includes:
        </p>
        <ul>
          <li>
            <p>
              Money laundering schemes or attempts to conceal the source of
              funds
            </p>
          </li>
          <li>
            <p>Embezzlement or misappropriation of funds</p>
          </li>
          <li>
            <p>
              Unauthorized gambling operations, including unofficial lotteries
              or betting
            </p>
          </li>
          <li>
            <p>
              Financial schemes designed to defraud users or circumvent legal
              restrictions
            </p>
          </li>
        </ul>

        <h3>National Symbols and National Security</h3>
        <p>
          Users must respect national emblems, including the Indian national
          flag, national anthem, and other officially recognized symbols of
          national importance. Content that disrespects, misuses, or demeans
          these symbols is strictly prohibited and violates our Community
          Guidelines.
        </p>

        <p>
          Additionally, content that threatens the unity, integrity, defence,
          security, or sovereignty of India, harms friendly relations with
          foreign states, disrupts public order, incites cognizable offenses,
          prevents the investigation of crimes, or insults other nations is also
          prohibited.
        </p>

        <h3>Property Protection</h3>
        <p>
          We are committed to respecting and safeguarding personal, public, and
          private property. Any actions or content that encourage harm to
          property or financial well-being are strictly prohibited. This
          includes:
        </p>
        <ul>
          <li>
            <p>Threats of vandalism or property destruction</p>
          </li>
          <li>
            <p>Extortion or financial threats</p>
          </li>
          <li>
            <p>Sharing information intended to facilitate theft</p>
          </li>
          <li>
            <p>
              Coordinating activities that may result in financial or property
              loss
            </p>
          </li>
        </ul>

        <h3>Responsibility for Content</h3>
        <p>
          As an intermediary under Indian law, we provide a platform for users
          to connect and share content while enforcing our Community Guidelines.
          However, users are solely responsible for the content they create,
          share, or interact with, and we are not directly liable for
          user-generated content or actions, except as required by law.
        </p>

        <p>
          We review reported content and take necessary action against
          violations, including removing content or restricting accounts. As an
          intermediary, we retain the right to enforce these measures to ensure
          a safe and compliant community. Our responsibility does not extend to
          third-party services or features accessed through our Platform.
        </p>

        <h3>Updates to Community Guidelines</h3>
        <p>
          We regularly review and update these Community Guidelines to better
          serve our community. If there are significant changes, we will notify
          users through official channels such as SMS or WhatsApp. By continuing
          to use FRND after any updates, you agree to the revised Community
          Guidelines. We also encourage you to check these Community Guidelines
          periodically to stay informed.
        </p>

        <h3>Indemnification</h3>
        <p>
          You agree to indemnify, defend, and hold harmless us, our
          subsidiaries, affiliates, agents, and their respective officers,
          directors, employees, successors, and assigns from and against any
          claims, proceedings, losses, damages, liabilities, costs, demands, or
          expenses, including but not limited to attorney fees, arising from:
        </p>
        <ul>
          <li>
            <p>Your access to or use of the Platform and Services</p>
          </li>
          <li>
            <p>
              Any breach of your obligations under these Community Guidelines
            </p>
          </li>
          <li>
            <p>
              Your violation of third-party rights, including intellectual
              property rights, privacy rights, or consumer protection rights
            </p>
          </li>
          <li>
            <p>
              Any violation of law or contractual obligations and any resulting
              claims, demands, or notices
            </p>
          </li>
          <li>
            <p>Your negligent acts or wilful misconduct</p>
          </li>
        </ul>

        <p>
          This indemnification obligation survives the termination of these
          Terms.
        </p>
      </div>
    </>
  );
};
