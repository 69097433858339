

export const NEW_LANGUAGE_DATA = {
  LOGIN: {
    en: {
      loginTitle: 'Login to FRND App',
      loginDescription: 'Enter your phone number to continue',
      numberNotRegistered: 'The number is not registered,',
      numberNotRegisteredDownloadApp: 'Download App ',
      registerNow: 'to register now',
      numberNotShared: 'We don’t share your number with anyone.',
      changeNumber: 'Change No',
      resendCodeTimer: 'Resend Code in',
      resendCodeButton: 'Resend Code',
      tncProceed: 'By proceeding I accept the',
      somethingWentWrong: 'Something went wrong',
      tnc: 'Terms of Use',
      enterVerficiation: 'Enter the verification code',
      communityguidelines:'Community Guidelines'
    },
    hi: {
    },
    tel: {
    },
    tam: {
    }
  },
  DOWNLOAD_APP: {
    en: {
      safeText: 'FRND app is 100% SAFE, Click “Download anyway” when you see a prompt now',
      downloadStartText: 'Download will begin in ',
      seconds: ' seconds',
    },
    hi: {
    },
    tel: {
    },
    tam: {
    }
  },
  FAQS: {
    en: {
      q1: 'What if coins did not get credited after payment success?',
      a1: '99% of the time coins get credited within 1 to 5 min. But if they haven\'t please wait up to 24 hours to reflect. In a very rare case, If the issue persists, please fill out this ',
      q2: 'What if the payment fails and the amount gets deducted from the bank?',
      a2: 'The amount will be refunded back to you in 7-10 working days. In a very rare case, if the issue persists, please fill out this '
    },
    hi: {
    },
    tel: {
    },
    tam: {
    }
  },
  PAYMENT_STATUS: {
    en: {
      processing: 'Payment Processing',
      pending: 'Payment Pending',
      success: 'Payment Success',
      failed: 'Payment Failed',
      failedDescription: '100% refund in 7 business days if any money is deducted and transaction fails',
      pendingDescription: 'Bank server are taking time to process. We will notify you on FRND app when the transaction is completed.',
      helpCenterButton: 'Help Center',
      coinsCredited: 'Coins Credited',
      appTimerText: 'If app does not open automatically then click button below'
    },
    hi: {
    },
    tel: {
    },
    tam: {
    }
  },
}