import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Profile from "./Components/Profile";
import JoinUs from "./Components/JoinUs";
import "./App.css";
import NewHome from "./pages/NewHome";
import DownloadApp from "./pages/DownloadApp";
import LoginScreen from "./pages/Login";
import PackagesScreen from "./pages/Packages";
import { PaymentSummary } from "./pages/PaymentSummary";
import { PaymentStatus } from "./pages/PaymentStatus";
import { PaymentFrame } from "./pages/PaymentFrame";
import { Careers } from "./pages/Careers";
import { PurchaseCallback } from "./pages/Callback";
import { Blog } from "./pages/Blog";
import { PrivacyPolicy } from "./pages/Privacy";
import BecomeGameHost from "./Components/JoinUsGameExpert";
import { TermsUse } from "./pages/TermsUse";
import { PaymentSummaryApp } from "./pages/PaymentSummaryApp";
import { SafetySection } from "./pages/SafetyCenter/SafetySection";
import JoinUsCeleb from "./Components/JoinUsCeleb";
import { LinkTester } from "./LinkTester";
import { ModalProvider } from "./Components/Common/Modal/context";
import APMProgram from "./Components/APMProgram";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { CommunityGuidelines } from "./pages/CommunityGuidelines";
function App() {
  return (
    <ModalProvider>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<NewHome />} />
            <Route path="profile/:userId" element={<Profile />} />
            <Route path="join-us" element={<JoinUs />} />
            <Route path="become-game-host" element={<BecomeGameHost />} />
            <Route path="become-frnd-celeb" element={<JoinUsCeleb />} />
            <Route path="apm-program" element={<APMProgram />} />
            <Route path="download-app" element={<DownloadApp />} />
            <Route
              path="login"
              element={
                <GoogleReCaptchaProvider
                  reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
                >
                  <LoginScreen />
                </GoogleReCaptchaProvider>
              }
            />
            <Route path="blog" element={<Blog />} />
            <Route path="privacy" element={<PrivacyPolicy />} />
            <Route path="terms-of-use" element={<TermsUse />} />
            <Route
              path="community-guidelines"
              element={<CommunityGuidelines />}
            />
            <Route path="careers" element={<Careers />} />
            <Route path="packages" element={<PackagesScreen />} />
            <Route
              path="webview/purchase-callback"
              element={<PurchaseCallback />}
            />
            <Route path="payment/:packageId" element={<PaymentSummary />} />
            <Route path="payment/status" element={<PaymentStatus />} />
            <Route path="payment/redirect" element={<PaymentFrame />} />
            <Route path="payment" element={<PaymentSummaryApp />} />
            <Route path="safety/*" element={<SafetySection />} />
            <Route path="link-test" element={<LinkTester />} />
            <Route path="*" element={<NewHome />} />
          </Routes>
        </BrowserRouter>
      </div>
    </ModalProvider>
  );
}

export default App;
