import { logOut } from "../../common/utils";
import APP_VERSION from "../../version";
import { getCookie } from "../cookie";

const SEND_OTP_URL = '/v2/sendOtp';
const VERIFY_OTP_URL = '/verifyOtp';
const REGISTER_MOBILE_URL = '/registerMobile';
const COIN_PACKAGES_URL = '/v1/getCoinPackages';
const RECOMMENDED_COUPON = '/getRecommendedCoupon';
const BUY_COIN_PACKAGE_URL = '/buyCoinPackage';
const PAYMENT_DETAILS_URL = '/getPaymentDetails';
const APPLY_COUPON_URL = '/checkDiscount';
const PAYMENT_STATUS_URL = '/paytm/coinOrderStatus';
const GET_ACCESS_TOKEN = '/oauth/access_token';
const SAFETY_DATA = '/trustAndSafetyData';
const GET_CAPTCHA_URl="/getCaptchaFlow"
const postHeaders = {
    method: 'POST',
    redirect: 'follow',
    headers: {
        "Content-Type": "application/json",
        AppVersion: process.env.REACT_APP_APP_VERSION,
        deviceType: "web",
        "X-App-Key": "64fd3km53n75k7sg62gx",
    },
}

const handleError = (err) => {
    console.log(err)
}

const postApi = async (url, body={}) => {
   const BASE_URL = process.env.REACT_APP_BASE_URL
    const accessToken = getCookie('access_token');
    if (accessToken) {
        postHeaders.headers.Authorization = `OAuth ${accessToken}`
    }
   
    try {
        const resp = await fetch(BASE_URL + url, {
            ...postHeaders,
            body: JSON.stringify(body)
        });
        if(resp.status === 401) {
            logOut("/login");
            return;
        }
        const res = await resp.json();
        if (res.error) {
            handleError(res.error);
            return res.error;
        } else {
            return res.data || {};
        }
    } catch (e) {
        handleError(e);
        return {
            error: {
                err_message: "API FAILED at " + url
            }
        }
    }
}



export const sendOtp = async ({
    mobile_no,captcha_token
}) => {
    return await postApi(SEND_OTP_URL, { mobile_no,captcha_token});

}
export const getCaptchaFlow =async(data)=>{
    return await postApi(GET_CAPTCHA_URl,data);
}
export const verifyOtp = async (data) => {
    return await postApi(VERIFY_OTP_URL, data);
}
export const registerMobile = async (data) => {
    return await postApi(REGISTER_MOBILE_URL, data);

}


export const getPackages = async (data) => {
    return await postApi(COIN_PACKAGES_URL, data);
}

export const getRecommendedCoupon = async (data) => {
    return await postApi(RECOMMENDED_COUPON, data)
}

export const applyCoupon = async (data) => {
    return await postApi(APPLY_COUPON_URL, data)
}

export const buyCoinPackage = async (data) => {
    return await postApi(BUY_COIN_PACKAGE_URL, data)
}

export const getPaymentDetails = async(data) => {
    return await postApi(PAYMENT_DETAILS_URL, data)
}

export const checkOrderStatus = async (data) => {
    return postApi(PAYMENT_STATUS_URL, data)
}

export const getAccessToken = async (data) => {
    return postApi(GET_ACCESS_TOKEN, data)
}
export const getSafetyData = async (data) => {
    return postApi(SAFETY_DATA, data)
}