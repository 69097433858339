import { Button } from "../../Components/Common/Button/Button";
import Header from "../../Components/Common/Header";
import { Carousel } from "../../Components/Common/Corousel/Carousel";
import styles from "./login.module.css";
import image1 from "./image_1.png";
import image2 from "./image_2.png";
import image3 from "./image_3.png";
import invalidIcon from "./invalid.svg";
import frndIcon from "./frndIcon.svg";
import { useEffect, useRef, useState } from "react";
import OtpInput from "react-otp-input";
import {
  registerMobile,
  sendOtp,
  verifyOtp,
  getCaptchaFlow,
} from "../../modules/networkCall";
import { useNavigate } from "react-router-dom";
import { getCookie, setCookie } from "../../modules/cookie";
import { Link } from "react-router-dom";
import LocalizedStrings from "react-localization";
import { registerStrings } from "../../modules/localisation/LocalisationModule";
import { NEW_LANGUAGE_DATA } from "../../modules/localisation/newTextData";
import { HomePageMetaPixel } from "../../common/utils";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Loader } from "../../Components/Common/Loader";
const LoginScreen = () => {
  let strings = new LocalizedStrings(NEW_LANGUAGE_DATA.LOGIN);
  registerStrings(strings);
  const [number, setNumber] = useState("");
  const [stage, setStage] = useState("number");
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [loginData, setLoginData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const navigate = useNavigate();
  const numberInputref = useRef();

  useEffect(() => {
    const authToken = getCookie("auth_token");
    if (authToken) {
      navigate("/packages", { replace: true });
    }
  }, []);

  useEffect(() => {
    if (stage === "number") {
      setTimeLeft(9);
      setOtp("");

      return;
    }
    if (!timeLeft) {
      return;
    }
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [stage, timeLeft]);

  const sendOtpHandler = async () => {
    setIsLoading(true);
    setError(false);
    if (!executeRecaptcha) {
      setError("recaptcha");
      return;
    }

    // Manually fetch token
    const recaptchaToken = await executeRecaptcha("login_action");

    const response = await getCaptchaFlow({});

    const data = await sendOtp({
      mobile_no: number,
      captcha_token: response?.is_captcha_active ? recaptchaToken : "",
    });

    if (data.error_code) {
      setError("signup");
      setNumber("");
    }
    if (data.is_new_user) {
      setError("signup");
      setNumber("");
    } else {
      if (data.verification_id) {
        setStage("otp");
        setLoginData(data);
      }
    }
    setIsLoading(false);
  };
  const verifyOtpHandler = async () => {
    setError(false);
    try {
      const data = await verifyOtp({
        ...loginData,
        otp,
      });

      if (data.error_message) {
        setError("otp");
        return;
      }

      const registerData = await registerMobile({
        ...loginData,
        device_id: "web",
      });

      if (registerData.mobile_no && registerData.access_token) {
        setCookie("auth_token", registerData.auth_token);
        setCookie("access_token", registerData.access_token);
        setCookie("user", JSON.stringify(registerData.user_details));
        window.fbq("trackCustom", "LoginSuccess", registerData.user_details);
        navigate("/packages", { replace: true }); // Only after successful registration
      } else if (registerData.err_message) {
        alert(registerData.err_message);
        window.location.href = "/";
      }
    } catch (error) {
      console.error("Verification Error:", error);
      setError("others");
    }
  };

  const resendOtpHandler = () => {
    setTimeLeft(9);
    sendOtpHandler();
  };

  return (
    <div className={styles.homeContainer}>
      <HomePageMetaPixel />
      <div className={styles.carouselWithHeader}>
        <div className={styles.mobCarousel}>
          <Header
            logoText="Login"
            customCrossAction={() => {
              navigate("/");
            }}
          />
          <Carousel
            onTouchStart={() => {
              numberInputref?.current?.blur();
              const otpRefs = document.querySelectorAll("#otp");
              otpRefs.forEach((ref) => ref.blur());
            }}
            height={50}
            images={[image1, image2, image3]}
          />
        </div>
        <div className={styles.overlay} />
        <div className={styles.desktopCarousel}>
          <Carousel height={100} images={[image1]} />
        </div>
      </div>
      <div className={styles.carouselDesktop}></div>
      {stage === "number" ? (
        <div className={styles.loginContainer}>
          <div className={styles.loginTitle}>{strings.loginTitle}</div>
          <div className={styles.loginDscription}>
            {strings.loginDescription}
          </div>
          <div className={styles.phoneInputContainer}>
            <div className={styles.code}>+91</div>
            <input
              ref={numberInputref}
              maxLength={10}
              value={number}
              type="tel"
              onChange={({ target }) => {
                setNumber(target.value);
              }}
              className={styles.phoneInput}
            />
          </div>
          {error === "signup" ? (
            <div className={styles.errorContainer}>
              <img alt="" src={invalidIcon} className={styles.invalidIcon} />
              {strings.numberNotRegistered}
              <img alt="" src={frndIcon} className={styles.invalidIcon} />
              <Link to="/download-app">
                {strings.numberNotRegisteredDownloadApp}
              </Link>
              {strings.registerNow}
            </div>
          ) : error === "others" ? (
            <div className={styles.errorContainer}>
              {strings.somethingWentWrong}
            </div>
          ) : (
            <div className={styles.loginHighlight}>
              {strings.numberNotShared}
            </div>
          )}

          {!isLoading ? (
            <Button
              onPress={sendOtpHandler}
              disabled={!/^(\+\d{1,3}[- ]?)?\d{10}$/.test(number)}
              style={{
                marginTop: "32px",
                fontWeight: "normal",
              }}
              buttonText={"Login"}
              backgroundColor={"#009A66"}
            />
          ) : (
            <div className={styles.loadercontainer}>
              <Loader width="30px" height="30px" />
              <p className={styles.verifying}> Verifying...</p>
            </div>
          )}
          <div className={styles.termsAndConditions}>
            {strings.tncProceed}{" "}
            <a href="/community-guidelines" className={styles.tnCButton}>
              {strings.communityguidelines}
            </a>{" "}
            &{" "}
            <a href="/terms-of-use" className={styles.tnCButton}>
              {strings.tnc}
            </a>{" "}
          </div>
        </div>
      ) : (
        <div className={styles.loginContainer}>
          <div className={styles.loginDscription}>
            {strings.enterVerficiation}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className={styles.loginTitle}>+91 {number}</div>
            <div
              onClick={() => {
                setStage("number");
                setNumber("");
              }}
              className={styles.changeNumber}
            >
              {strings.changeNumber}
            </div>
          </div>
          <OtpInput
            value={otp}
            inputType="number"
            onChange={setOtp}
            containerStyle={styles.otpContainer}
            numInputs={6}
            inputStyle={`${styles.otpInput} ${
              error === "otp" ? styles.otpInputError : ""
            }`}
            renderInput={(props, index) => <input id={"otp"} {...props} />}
          />
          <div className={styles.errorContainerOtp}>
            <div className={styles.errorMessage}>{error}</div>
            {timeLeft ? (
              <div className={styles.resendCode}>
                {strings.resendCodeTimer}{" "}
                <span className={styles.resendCodeHighlight}>
                  00:0{timeLeft}
                </span>
              </div>
            ) : (
              <div
                onClick={resendOtpHandler}
                className={styles.resendCodeHighlight}
              >
                {strings.resendCodeButton}
              </div>
            )}
          </div>

          <Button
            onPress={verifyOtpHandler}
            disabled={otp.length !== 6}
            style={{
              marginTop: "32px",
              fontWeight: "normal",
            }}
            buttonText={"Verify"}
            backgroundColor={"#009A66"}
          />
          <div className={styles.termsAndConditions}>
            {strings.tncProceed}{" "}
            <a href="/community-guidelines" className={styles.tnCButton}>
              {strings.communityguidelines}
            </a>{" "}
            &{" "}
            <a href="/terms-of-use" className={styles.tnCButton}>
              {strings.tnc}
            </a>{" "}
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginScreen;
