import styles from './list.module.css';
import coins from './coins.png';
import { useEffect, useState } from 'react';
import { getPackages } from '../../../modules/networkCall';
import { useNavigate } from 'react-router-dom';
import { getCookie } from '../../../modules/cookie';

const PackageItem = ({id, image_url, coins, is_popular, save_string, price, coupon, upiOptions,website_discount, original_price }) => {
  const navigate = useNavigate();

  const [coupon_code,setCoupon_code]=useState(coupon?.coupon_code)

  const navigateToSummary = () => {
    const upiOptionsString = upiOptions ? `?upi_handlers=${upiOptions}`: ''
    navigate('/payment/' + id + upiOptionsString, {
      state: 
        {
          coins, price, coupon_code, website_discount, original_price
        }
    })
    
  }
  return (
    <div key={id} onClick={navigateToSummary} className={`${is_popular ? styles.popularItem : null} ${styles.itemContainer}`}>
    {price && <div className={styles.item}>
       {is_popular &&  <div className={styles.popularText}>POPULAR</div>}
      <img alt='coins-icon' src={image_url} className={styles.coinsImage} />
      <div className={styles.coinsValue}>{coins}</div>
      <div className={styles.coinsText}>Coins</div>
      <div className={styles.priceContainer}>

       {save_string &&  <div className={styles.discountText}>{save_string}</div>}
       <div className={styles.price}>{original_price && <div className={styles.strikePrice}>₹{original_price}</div>}₹{price}</div>
       </div>
    </div>}
    </div>
  )
}

const PackageShimmer = ({id}) => {
  return (
    <div key={id} className={`${styles.itemContainer}`}>
    <div className={styles.item}>
      {<div className={styles.popularText}>POPULAR</div>}
      <img alt='coins-icon' src={coins} className={styles.coinsImage} />
      <div className={styles.coinsValue}>0</div>
      <div className={styles.coinsText}>Coins</div>
      <div className={styles.priceContainer}>
          <div className={styles.discountText}>Save 20%</div>

          <div className={styles.price}>Loading</div>
      </div>
    </div>
    </div>
  )
}


export const PackageList = () => {

  const [packages, setPackages] = useState([]);
  const [coupon, setCoupon]=useState(null);
  const [upiOptions, setUPIOptions]=useState(null)

  const getPackagesHelper = async() => {
    const device_type = getCookie('device_type');
    const data = await getPackages({
      redirect_from: device_type
    });
    
    let usablePackages = [];
    if(data.packages) {
      usablePackages = data.packages.filter((pkg) => pkg.type === "COIN")
    }
    if(data.upi_handlers) {
      setUPIOptions(data.upi_handlers)
     
    }
    const numColumns = window.innerWidth > 1024 ? 5 : 3;
    while(usablePackages.length % numColumns) {
      usablePackages.push({id: usablePackages.length});
    }
    setPackages(usablePackages);
  }


  useEffect(() => {
    getPackagesHelper()
  },[])


  return (
    <div className={styles.packageListContainer}>
      {
        packages.length ? packages.map((pkg, index) => <PackageItem upiOptions={upiOptions} key={'package'+index} coupon={coupon} {...pkg} />) : (new Array(9)).fill("1").map((_, index) => <PackageShimmer key={index} id={index} />)
      }
    {}
    </div>
  )
}