import Header from "../../Components/Common/Header";
import styles from "./styles.module.css";
export const TermsUse = () => {
  return (
    <>
      <Header forceActive />
      <div id="container" className={styles.docContainer}>
        <h1 className={styles.frndcommunity}>
         Terms of Use
        </h1>
        <p>Last updated: 12 March 2025</p>
        <p>
          These Terms of Use ("Terms") govern your use of FRND mobile
          application (the "Platform") made available by Cold Brew Tech Private
          Limited ("FRND", "Company", "we", "us" and "our"), a private company
          established under the laws of India having its registered office at
          Urban Vault No. 1350 19th Main, 17th Cross Road, HSR Layout 1st sector
          opposite Gnan shrishti school Bengaluru, Karnataka India, 560102 . The
          terms "you" and "your" refer to the user of the Platform. You agree to
          be bound by the Terms by using the Platform.
        </p>

        <p>
          These Terms, the FRND Privacy Policy, and Community Guidelines(“{" "}
          <strong>Platform Policies</strong>”) will be read together. The
          Platform Policies set out the rules you are required to follow while
          using the Platform.
        </p>
        <p>
          We may periodically update these Terms, and will post the updated
          Terms on this link. Your continued use of the Services will constitute
          your acceptance of the updated Terms. We will notify you from time to
          time to update you of any material changes made to these Terms.
        </p>
        <h3>CHANGES TO SERVICES</h3>
        <p>
          Our Platform is dynamic and may change rapidly. As such, we may change
          the services we provide at our discretion. We may temporarily or
          permanently stop providing Services or any features to you generally.
        </p>
        <p>
          We may remove or add functionalities to our Platform and Services
          without any notice. However, if we make a change where your consent is
          required, we will make sure to ask for it. Please be sure to keep
          visiting this page from time to time to stay updated on our latest
          changes and developments.
        </p>

        <h3>WHO MAY USE OUR SERVICES</h3>
        <p>
          Our Platform helps you stay in touch with your friends and enables you
          to share content including images and videos in your preferred
          regional language ("Service/Services").
        </p>

        <p>
          To use our Services, you must register on our Platform by entering
          your phone number and the One-Time-Password sent by us via SMS to your
          phone number. You may use our Services only if you are capable of
          forming a binding agreement with us and are legally permitted to use
          our Services. If you are accepting these Terms on behalf of a company
          or any legal persons, then you represent and warrant that you have the
          authority to bind such an entity to these Terms and effectively "you"
          and "your" shall refer to the company.
        </p>

        <h3>PRIVACY POLICY</h3>
        <p>
          To effectively provide and introduce any new Services to you, we
          collect certain information such as your phone number, your gender and
          your name from you. We may further request and store additional
          information. The FRND Privacy Policy explains how we collect, use,
          share and store the information collected. The FRND Privacy Policy
          also details your rights under law and how you may control the data
          you provide us.
        </p>
        <h3>PURCHASE AND USAGE OF COINS AND GIFTS</h3>
        <p>
          You can acquire coins (“Coins”) using payment methods provided by us
          on the Platform. You can use these Coins to play games on the
          Platform, licence virtual gifts/digital goods (such as stickers, gifs,
          banners etc) to gift them to other users or customise your profile.
          Coins/Gifts are not treated as individual goods to be purchased by
          users but as licenced access to items issued by the Platform. Do note
          that Coins/Gifts cannot be exchanged for cash, legal tender, or any
          form of credit and cannot be used for trading on the internet. You are
          solely responsible for the Coins/ Gifts you purchase on the Platform
          and you agree that we will have no liability or responsibility with
          respect to such Coins/ Gifts.
        </p>
        <h3>
          <strong>Purchasing Coins</strong>
        </h3>
        <ul>
          <li>
            <p>
              The price of the Coins will be displayed at the point of sale, and
              will be inclusive of applicable taxes.
            </p>
          </li>
          <li>
            <p>
              You will be responsible for the payment of any Coins acquired by
              you. Once your payment has been made, your user account will be
              credited with the number of Coins acquired by you. We cannot
              provide a refund once a successful payment has been made.
            </p>
          </li>
        </ul>

        <h3>Usage of Coins</h3>
        <ul>
          <li>
            <p>
              Coins can only be used on our Platform and as a part of our
              Services, and cannot be combined or used in conjunction with other
              promotions, coupons, discounts, or special offers, except those
              designated by us.
            </p>
          </li>
          <li>
            <p>
              No Coins may be assigned or transferred to any other user of the
              Platform or any third party. The sale, barter, assignment, or
              other disposal of any Coins, other than by us, is expressly
              prohibited.
            </p>
          </li>
          <li>
            <p>
              Any violation of the Terms, or applicable laws, including any
              fraudulent activities, may result in termination of your account
              on the Platform, forfeiture of the Coins from your account, and/or
              you may be subject to liability for damages, litigation, and
              transaction costs.
            </p>
          </li>
          <li>
            <p>
              Accrued Coins do not constitute property and are not transferable
              upon death or otherwise, including by operation of law.
            </p>
          </li>
          <li>
            <p>
              Coins and Gifts associated with your account will be extinguished
              once you delete your account from our Platform.
            </p>
          </li>
          <li>
            <p>
              <strong>
                COINS WILL EXPIRE AND BE AUTOMATICALLY FORFEITED IF NOT USED
                WITHIN SIX (6) MONTHS (182 DAYS) FROM THE DATE OF PURCHASE. NO
                REFUNDS OR COMPENSATION WILL BE PROVIDED FOR EXPIRED OR
                FORFEITED COINS.
              </strong>
            </p>
          </li>
        </ul>

        <h3>Purchasing Gifts</h3>
        <ul>
          <li>
            <p>
              Gifts constitute a limited license to certain digital products and
              services. The number of Coins using which you can acquire each
              Gift will be displayed on our Platform. We have the right to
              revise this at our sole discretion.
            </p>
          </li>
          <li>
            <p>
              Gifts cannot be converted into or exchanged for Coins or cash or
              be refunded or reimbursed by us for any reason.
            </p>
          </li>
          <li>
            <p>
              Gifts exchanged or received by any user do not constitute property
              and are not transferable upon death or otherwise, including by
              operation of law.
            </p>
          </li>
          <li>
            <p>
              We may replace previously exchanged copies of Gifts, if we
              determine in our sole discretion that the Gifts exchanged or
              received by a user are corrupted or otherwise damaged. We will not
              charge additional fees for reissuing a corrupted or otherwise
              damaged Gift.
            </p>
          </li>
          <li>
            <p>
              You are not permitted to use any Gift or Coin in lieu of receipt
              of any goods or services from any user on the Platform or
              otherwise.
            </p>
          </li>
        </ul>

        <h3>GAMES AND REWARDS</h3>

        <p>
          Any games and rewards on the Platform are made available as
          promotional activities for our users. As a promotional feature, such
          games and rewards are offered on as is where is basis, without any
          guarantees regarding availability or error-free operation, and we make
          no warranties or representations regarding them. Games on our platform
          cannot be played with real money. All rewards are provided free of
          charge and cannot be exchanged for cash, transferred into real
          currency, or used as the basis for any monetary claims against us.
        </p>
        <p>
          Please independently verify that your participation in games on the
          Platform is permitted under local laws applicable to you. We do not
          provide games in any geography where it may be unlawful for us to do
          so. We have the right to cancel, suspend or void your rewards if
          identify that you are participating from a prohibited location.
        </p>
        <p>
          Participation in the game or receipt of rewards does not permit any of
          the following:
        </p>
        <ol type="a">
          <p>(a) &nbsp;&nbsp;&nbsp;Derivative uses of our Services.</p>
          <p>
            (b)&nbsp;&nbsp;&nbsp;Copying, reverse engineering, or otherwise
            modifying or exploiting our technology, files, or software.
          </p>
          <p>
            (c)&nbsp;&nbsp;&nbsp;Downloading or copying account information for
            third-party benefit.
          </p>
          <p>
            (d)&nbsp;&nbsp;&nbsp;Use of data mining tools, robots, or similar
            data extraction methods.
          </p>
        </ol>
        <p>
          You are prohibited from engaging in cheating, fraud, collusion, or any
          unfair gaming practices on the Platform. If we determine or suspect
          that any individual has engaged in such activities, we reserve the
          right to revoke, suspend, or invalidate any rewards already granted or
          pending, as well as to suspend or terminate the accounts of those
          users on the Platform.
        </p>
        <p>
          <strong>
            THE BALANCE IN WALLET ACCUMULATED FROM YELLOW ROSES, LEADERBOARDS OR
            ANY OTHER ACTIVITY WILL EXPIRE AND BE FORFEITED IF THERE IS
            INACTIVITY IN THE WALLET FOR 6 MONTHS (180 DAYS) NO REFUNDS OR
            COMPENSATION WILL BE PROVIDED FOR EXPIRED OR FORFEITED BALANCE.
          </strong>
        </p>

        <h3>YOUR COMMITMENTS</h3>
        <p>By using our Services, you agree and acknowledge the following:</p>

        <p>
          <strong>
            a.&nbsp;&nbsp;&nbsp; No Impersonation or False Information to be
            Provided
          </strong>
        </p>
        <p>
          While you do not have to use your actual name on our Platform, you are
          required to input your correct phone number and gender to use our
          Services. You will not falsely represent yourself as another person or
          representative of another person on the Platform. You will not use our
          Platform to deceive others or spread false information, misinformation
          or content which misleads users about its origin.
        </p>

        <p>
          <strong>b.&nbsp;&nbsp;&nbsp; Device Security</strong>
        </p>
        <p>
          We have implemented measures to ensure that our Platform is secure.
          However, there is no guarantee that our Platform is immune to hacking
          and virus attacks. You shall not use automated means or bots to access
          the Platform or collect user information. You will also not allow
          multiple accounts to be linked to your profile through any means,
          including but not limited to your phone number, PAN number, IP
          address, UPI ID, bank account details, etc. You will be responsible
          for all content posted by any account linked through these means.
        </p>

        <p>
          <strong>c.&nbsp;&nbsp;&nbsp; Content Removal and Termination</strong>
        </p>
        <p>
          If any of our users report your content which violates the Terms, we
          may remove such content from our Platform. We reserve the right to
          suspend or terminate your account and block you from re-registering
          with us if your content violates the Terms.
        </p>

        <p>
          <strong>d. Respecting the Rights and Privacy of Other Users</strong>
        </p>
        <p>
          You must respect the rights and privacy of others. You will not share
          any content that belongs to another person without their permission,
          or otherwise use the Platform in any manner which violates or
          infringes any person’s intellectual property rights including any
          patent, trademark, copyright, or privacy rights or publicity rights.
        </p>
        <p>
          You will not post content that invades the privacy of another person
          or harasses users based on their gender, race, religion, caste, or
          ethnicity. You will not create or share content that promotes hatred
          or incites violence between different groups.
        </p>

        <p>
          <strong>
            e.&nbsp;&nbsp;&nbsp;Respecting FRND's Intellectual Property Rights
          </strong>
        </p>

        <p>
          FRND owns all rights to the Platform, including all related brands,
          content, software, features, and technology. You agree to respect
          FRND’s intellectual property rights including any patent, trademark,
          copyright or other proprietary rights, and not do, attempt to do, or
          encourage others to do the following:
        </p>
        <ul>
          <li>
            <p>
              Use FRND's branding, logos, designs, interface elements,
              photographs, videos, or other materials available through the
              Platform, except as explicitly permitted by these Terms or our
              guidelines.
            </p>
          </li>
          <li>
            <p>
              Copy, modify, download, distribute, sell, broadcast, display, or
              create derivatives of the Platform or its content, except as
              expressly permitted in these Terms or enabled by the Platform's
              intended functionality.
            </p>
          </li>
          <li>
            <p>
              Reverse engineer, decompile, or extract the source code of the
              Platform or its features.
            </p>
          </li>
          <li>
            <p>
              Develop or use third-party applications that interact with the
              Platform or user content without our written permission.
            </p>
          </li>
        </ul>

        <p>
          <strong>
            f.&nbsp;&nbsp;&nbsp; Platform Not to be Used For Anything Unlawful
            or Illegal
          </strong>
        </p>

        <p>You shall not use our Platform to share any content which is:</p>
        <ul>
          <li>
            <p>Obscene, pornographic,</p>
          </li>
          <li>
            <p>Harmful for minors, or paedophilic,</p>
          </li>
          <li>
            <p>
              Discriminatory or involves hate speech, or incites any form of
              violence or hatred against any persons,
            </p>
          </li>
          <li>
            <p>
              Threatens national security, unity, integrity, defence, security,
              sovereignty, or international relations,
            </p>
          </li>
          <li>
            <p>Promotes gambling, online gaming, or money laundering,</p>
          </li>
          <li>
            <p>
              Interferes with law enforcement activities, or otherwise uses the
              Platform for any illegal activities, or
            </p>
          </li>
          <li>
            <p>Otherwise violates any laws in force.</p>
          </li>
        </ul>

        <p>
          <strong>g.&nbsp;&nbsp;&nbsp;Content Rights and Liabilities</strong>
        </p>

        <p>
          We strongly believe in the freedom of expression and allow you to
          share photographs, images, videos, and other content on our Platform.
          We do not have any ownership over any of the content shared by you and
          the rights in the content remain only with you.{" "}
        </p>
        <p>
          You remain solely responsible for the content you post on our
          Platform. We do not endorse and are not responsible for any content
          shared or posted on or through our Platform, and for any consequences
          resulting out of such sharing or posting. The presence of our logo or
          any trademark on any content shared by you does not mean that we have
          endorsed or sponsored your content.
        </p>

        <p>
          We will not be liable or responsible for the consequences of any
          transactions made or entered into by you with other users of the
          Platform or advertisers on the Platform
        </p>
        <p>
          FRND may access, review, and remove your content at any time for any
          reason, including to improve our Services or if we believe your
          content violates these Terms or applicable laws.
        </p>
        <p>
          You may delete your content and/or account at any point. However, your
          content may continue to appear on the Platform if it has been shared
          with others. To learn more about how we use information, and how to
          control or delete your content, please read the FRND Privacy Policy.
        </p>

        <strong>h.&nbsp;&nbsp;&nbsp;Third-Party Materials and Services</strong>

        <p>
          The Platform may display content, features, or materials from third
          parties and allow access to third-party services. Your use of any
          third party materials or services is governed by the respective third
          party's terms. FRND is not responsible for any aspect of the third
          party materials or services, including their content, accuracy,
          quality, or availability. We do not endorse or assume liability for
          any third party materials or services available through the Platform,
          including any third-party links or materials posted by other users of
          the Platform.
        </p>
        <p>
          <strong>
            i.&nbsp;&nbsp;&nbsp;Intermediary Status and No Liability
          </strong>
        </p>

        <p>
          We are an intermediary as defined under the Information Technology
          Act, 2000 and the Information Technology (Intermediary Guidelines and
          Digital Media Ethics Code) Rules, 2021. These Terms are published in
          accordance with the provisions of Rule 3(1)(a) of the Information
          Technology (Intermediary Guidelines and Digital Media Ethics Code)
          Rules, 2021 that require publishing of the rules and regulations, FRND
          Privacy Policy, and FRND Terms of Use for accessing and using our
          Platform. Our role is limited to providing a platform for users to
          upload, share and display content, created or shared by you and other
          users.
        </p>

        <p>
          Content on the Platform is primarily created by users and third
          parties. While FRND reserves the right to review and remove content,
          we do not review all content posted on the Platform. We are not
          responsible for the consequences of such actions (whether online or
          offline) or for services and features offered by others, even if you
          access them through our Services. Each user is solely responsible for
          the content they submit, whether shared publicly or privately. Users
          may report any content or accounts that violate our Terms through the
          reporting mechanisms provided on the Platform and in these Terms.
        </p>

        <p>
          Our responsibility for anything that happens on our Platform is
          strictly governed by the laws of India and is limited to that extent.
          You agree that we will not be responsible for any loss of profits,
          revenues, information, or data, or consequential, special, indirect,
          exemplary, punitive, or incidental damages arising to you or any other
          person related to these Terms, even if we know they are possible. This
          includes when we delete your content, information, or account.
        </p>

        <strong>
          j.&nbsp;&nbsp;&nbsp;You Will Not Attempt to Disrupt or Jeopardise FRND
          services
        </strong>

        <p>
          We have developed a community-driven platform. Therefore, you agree to
          not interfere with, or use non-public areas of our Platform, Services,
          and our technical delivery system. You will not introduce any trojans,
          viruses, any other malicious software or any other computer code, file
          or program, or any bots or scrape our Platform for any user
          information. You will not create new accounts after we have disabled
          your account, use unauthorised applications to access the Platform,
          request login details from other users, or trade in account access.
        </p>
        <p>
          Additionally, you will not probe, scan, or test the vulnerability of
          any system, security or authentication measures implemented by us. If
          you tamper or attempt to tamper with our technological design and
          architecture, we will terminate your user profile and ban you from
          using our services. We may further report such actions to the
          appropriate law enforcement authorities and proceed against you with
          legal actions.
        </p>

        <h3> 1. Broadcast</h3>
        <p>
          As part of the FRND platform we provide the Broadcast feature which
          allows you to broadcast real-time videos, audios of yourself on the
          platform. Other users can participate in live videos, audio
          broadcasted by you thus allowing for real-time communication.
        </p>
        <p>
          All content uploaded using the Broadcast feature is subject to the
          FRND Terms and Privacy Policy. We reserve the right to immediately
          remove any content uploaded using this feature which is in violation
          of these Terms.
        </p>
        <p>
          We may, from time to time, add, remove or alter the functionality of
          the Broadcast feature. We may also discontinue the Broadcast feature
          from the FRND platform at any time. We do not warrant that the
          Broadcast feature will be error-free or always available, the
          Broadcast feature will always function without disruptions, or any
          content posted by other users using the Broadcast feature will be
          accurate. We provide the Broadcast feature for you to interact on a
          real-time basis with other users but you shall not misuse the feature
          to upload content that is prohibited under the FRND Terms and Privacy
          Policy. We do not warranty the continued availability of this feature.
        </p>

        <h3>PERMISSIONS YOU GIVE TO US</h3>
        <p>
          {" "}
          You accept these Terms and give us certain permissions so that we can
          serve you better. Permissions you grant to us are:
        </p>
        <h4>Licence to content</h4>
        <p>
          By creating/sharing/posting/uploading content using our Services, you
          grant us a non-exclusive, royalty-free, transferable, sub-licensable,
          worldwide licence to host, use, distribute, run, copy, publicly
          perform or display, translate, and create derivative works of your
          content (consistent with your privacy and application settings).
        </p>

        <h4> Automatic Downloads and Updates</h4>

        <p>
          We are constantly updating our Platform and Services offered. To use
          our Platform, you may need to download the FRND mobile application to
          your mobile device and update it from time to time. Applications and
          software are constantly updated for your use and you will need to
          install the latest version of the FRND mobile application to your
          mobile device each time such update is generated.
        </p>

        <h4>WHAT HAPPENS IF WE DISAGREE</h4>
        <p>
          In all cases, you agree that disputes will be subject to the laws of
          India and the courts of Bangalore shall have exclusive jurisdiction
          over all such disputes.
        </p>

        <h4>GRIEVANCE REDRESSAL AND REPORTING</h4>
        <p>
          As part of our commitment to the privacy and safety of our users, we
          continue to work alongside the government authorities in order to keep
          our users safe. We have put together a robust Grievance Redressal
          Mechanism in place to help resolve any concerns or complaints raised
          by you with respect to FRND
        </p>

        <h4>Various mechanisms for grievance redressal are given below:</h4>

        <ol>
          <li>
            {" "}
            <p>
              You can report user profiles and raise complaints for content that
              violates our Terms. You can select the appropriate reason and
              click on the report option.
            </p>
          </li>
          <li>
            <p>
              You can send an email to{" "}
              <a href="mailto:care@frnd.app">care@frnd.app</a> with your concern
              or complaint.
            </p>
          </li>
          <li>
            <p>
              You may contact the Grievance Officer at any of the following:
            </p>
            <p>Name: Mr. Shubham Surana</p>
            <p>
              {"Email: "}
              <a href="mailto:grievance.officer@frnd.app">
                grievance.officer@frnd.app
              </a>
            </p>
            <p>Address: FRND (Cold Brew Tech Private Limited)</p>
            <p>Urban Vault No. 1350 19th Main, 17th Cross Road</p>

            <p>HSR Layout 1st sector Opposite Gnan shrishti school Bengaluru</p>

            <p>Karnataka India, 560102</p>
          </li>
        </ol>

        <h3>LIMITATION OF LIABILITY</h3>
        <p>
          We do not assume any liability with respect to any loss or damage,
          arising directly or indirectly due to any inaccuracy or incompleteness
          of any information or a breach of any warranty or guarantee due to the
          actions of any user of the Platform.
        </p>
        <p>
          The Platform and Services are provided on "as is" and "as available"
          basis without any representation or warranties, express or implied
          except otherwise specified in writing. We do not warrant the quality
          of the Services or the Platform including its uninterrupted, timely,
          secure or error-free provision, continued compatibility on any device,
          or correction of any errors.
        </p>
        <p>
          In no event shall we, or any of our affiliates, successors, and
          assigns, and each of their respective investors, directors, officers,
          employees, agents, service providers, and suppliers be liable for any
          special, incidental, punitive, direct, indirect or consequential
          damages suffered as a consequence of a breach of the Terms by another
          user or arising out of the use of or the reliance on any of the
          Services or the Platform.
        </p>
        <p>
          In the event any exclusion contained herein is held to be invalid for
          any reason and we or any of our affiliate entities, officers,
          directors or employees become liable for loss or damage, then, any
          such liability shall be limited to not exceeding the charges or
          amounts paid to us for use of the Platform or the Services in the
          month preceding the date of the claim.
        </p>

        <h3>ASSIGNMENT</h3>
        <p>
          The rights and obligations under these Terms are granted only to you
          and shall not be assigned to any third party without our consent.
          However, we are permitted to assign our rights and obligations under
          these Terms to others. This can happen when, for example, if the
          Company or substantially all of its assets are acquired by a third
          party, in which case personal data held by it about its customers will
          be one of the transferred assets. In such case your information would
          be transferred or become subject to a different Privacy Policy, we
          will notify you in advance.
        </p>
        <h3>TERMINATION AND SUSPENSION</h3>
        <p>
          You may terminate these Terms by deleting your account from the
          Platform. FRND reserves the right to restrict, suspend, terminate, or
          take other appropriate action impacting your access to the Platform if
          you fail to comply with these Terms, violate applicable laws, for
          reasons beyond our control, or for any other reason. We reserve the
          right to determine, in our sole discretion, as to what constitutes a
          violation.
        </p>
        <p>
          We reserve the right to terminate these Terms, cease providing any or
          all Services, or impose additional limits on your Platform usage. We
          may also deactivate accounts due to prolonged inactivity and reclaim
          usernames. While we will endeavour to provide reasonable notice before
          taking such actions, this may not be possible in all circumstances.
        </p>
        <p>
          For violations of our Terms and Privacy Policy, we will notify you of
          any restriction, suspension, or termination and provide an opportunity
          to appeal our decision.
        </p>
        <p>
          Regardless of which party terminates these Terms, provisions relating
          to intellectual property rights, liability limitations,
          indemnification, and dispute resolution shall continue to remain in
          effect.
        </p>

        <h3>INDEMNIFICATION</h3>
        <p>
          You agree to indemnify, defend and hold harmless us, and our
          subsidiaries, affiliates and agents and their respective officers,
          directors, employees, successors and assigns from and against any
          claim, proceeding, loss, damage, liability, cost, demand or expense
          (including but not limited to attorney's fees) of any kind arising out
          of:
        </p>

        <p>(i) your access to or use of the Platform and Services;</p>

        <p>(ii) any breach by you of your obligations under this Agreement;</p>

        <p>
          (iii) your violation of the rights of any third party, including any
          infringement of intellectual property, or of any privacy or consumer
          protection right;
        </p>

        <p>
          (iv) any violation of law or contractual obligation and any claims,
          demands, notices pursuant to such violation;
        </p>

        <p>(v) your negligence or willful misconduct.</p>

        <p>This obligation will survive the termination of our Terms.</p>
        <h3>UNSOLICITED MATERIAL</h3>
        <p>
          We always appreciate feedback or other suggestions. We may use the
          same without any restrictions or obligation to compensate you for them
          and are under no obligation to keep them confidential.
        </p>
        <div>
  <h3>Prohibition of Child Sexual Abuse and Exploitation (CSAE)</h3>

  <h3>1. Zero-Tolerance Policy</h3>
  <p>
    Our platform strictly prohibits any content, activity, or behavior that involves the sexual abuse, exploitation, or endangerment of children. This includes but is not limited to:
  </p>
  <ul>
    <li><p>Sharing, uploading, or distributing child sexual abuse material (CSAM), including any images, videos, or texts that depict or exploit minors.</p></li>
    <li><p>Grooming or attempting to engage minors in sexually explicit conversations, interactions, or exploitation.</p></li>
    <li><p>Any conduct violating the <strong>POCSO Act, 2012, IT Act, 2000, </strong>and other applicable Indian laws.</p></li>
  </ul>

  <h3>2. Reporting and Compliance</h3>
  <ul>
 <li>
 <p>
    Any user encountering such content must report it immediately using the in-app reporting feature or by contacting our<strong>Grievance Officer </strong> (contact details below).
  </p>
 </li>
 <li>
 <p>
    We actively cooperate with Indian law enforcement agencies, including the <strong>National Crime Records Bureau (NCRB)</strong> and <strong>Interpol</strong>, for investigations related to CSAE.
  </p>
 </li>
 <li>
 <p>
    In compliance with the <strong>IT (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021,</strong> we will take down any reported content within <strong>24 hours</strong>  and preserve necessary records for legal authorities.
  </p>
 </li>
</ul>
  <h3>3. Enforcement and Actions</h3>
  <ul>
    <li><p>Any user found violating these policies will face immediate <strong>account suspension, permanent ban, and legal action</strong> under relevant Indian laws.</p></li>
    <li><p>We may also report such users to relevant authorities, including the <strong>Cyber Crime Portal</strong> (<a href="https://www.cybercrime.gov.in">www.cybercrime.gov.in</a>) and law enforcement agencies.</p></li>
  </ul>

  <h3>4. Protection and Safety Measures</h3>
  <ul>
    <li><p>We use <strong>AI-based detection </strong>and <strong>human moderation</strong> to prevent the circulation of CSAM.</p></li>
    <li><p>Our app has <strong>age verification</strong>  and <strong>parental control features</strong> to protect minors from exploitation.</p></li>
    <li><p>Users engaging with minors are required to follow strict <strong>child safety guidelines.</strong></p></li>
  </ul>

  <h3>5. Contact and Reporting</h3>
  <p>
    If you come across any violations of this policy, please report them immediately:
  </p>
  <ul>
    <li><p>📩 <strong>Email:</strong> <a href="mailto:grievance.officer@frnd.app">grievance.officer@frnd.app</a></p></li>
    <li><p>🌐 <strong>Cyber Crime Reporting:</strong> <a href="https://www.cybercrime.gov.in">www.cybercrime.gov.in</a></p></li>
  </ul>
</div>

        <h3>GENERAL</h3>
        <ol>
          <li>
            <p>
              If any aspect of these Terms is unenforceable, the rest will
              remain in effect.
            </p>
          </li>
          <li>
            <p>
              Any amendment or waiver to our Terms must be in writing and signed
              by us.
            </p>
          </li>
          <li>
            <p>
              If we fail to enforce any aspect of these Terms, including
              reporting any illegal or impermissible actions to appropriate law
              enforcement authorities or blocking or suspending your profile,
              such failure to enforce our rights will not be a waiver by us.
            </p>
          </li>
          <li>
            <p>We reserve all rights not expressly granted to you.</p>
          </li>
        </ol>
      </div>
    </>
  );
};
