import { useEffect, useState } from "react";
import { Button } from "../../Components/Common/Button/Button";
import Header from "../../Components/Common/Header";
import { CouponApplier } from "./components/CouponApplier";
import styles from "./styles.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Loader } from "../../Components/Common/Loader";
import {
  applyCoupon,
  buyCoinPackage,
  getRecommendedCoupon,
} from "../../modules/networkCall";
import safety from "./safety.png";
import successIcon from "./success.svg";
import hero from "./hero.png";
import image1 from "./image1.png";
import image2 from "./image2.png";
import { CarouselDesktop } from "../../Components/Common/CorouselDesktop/CarouselDesktop";
import { Benifits } from "../Packages/components/Benifits";
import { useParams } from "react-router-dom";
import { HomePageMetaPixel } from "../../common/utils";
import { setCookie } from "../../modules/cookie";
import phonePe from "./phonepe.png";
import back from "../../Components/Common/Header/back.png";
import gpay from "./gpay.png";
import { useQuery } from "../PaymentSummaryApp";
import { HERO_PAYMENT_VIDEO } from "../PaymentSummaryApp/constants";
export const UPI_DATA_MAP = {
  phonepe: {
    icon: phonePe,
    displayName: "PhonePe",
  },
  gpay: {
    icon: gpay,
    displayName: "Google Pay",
  },
};
export const PaymentSummary = () => {
  const { state } = useLocation();
  
  const { packageId } = useParams();
  const params = useQuery();
  const [upi_handler] = [params.get("upi_handlers")];
  const UPI_OPTIONS = upi_handler ? upi_handler?.split(",") : [];

  const [selectedUpiOption, setSelectedUpiOption] = useState(UPI_OPTIONS[0]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [discount, setDiscount] = useState(0);
  const [finalPrice, setFinalPrice] = useState(0);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  const helper = async () => {
    
    const data = await getRecommendedCoupon({ package_id: packageId });
    if (data && data.coupon_code) {
      state.coupon = data.coupon_code;
    }

    setIsLoading(false);
  };

  const handleOrderPlacement = async (forceWeb) => {
    setIsLoading(true);
    let apiBody = {
      payment_method: "WEB",
      coin_package_id: Number(packageId),
      preferred_upi: selectedUpiOption,
    };
    if (selectedCoupon) {
      apiBody = {
        ...apiBody,
        ...selectedCoupon,
      };
    }
    const data = await buyCoinPackage(apiBody);
    window.fbq("track", "InitiateCheckout", {
      content_ids: [packageId],
      value: finalPrice,
      currency: "INR",
    });
    if (data.order_id) {
      setCookie("order_source", "web");
      if (
        window.innerWidth < 500 &&
        !forceWeb &&
        data?.juspay?.payment_url?.default
      ) {
        window.location.href = data?.juspay?.payment_url?.default;
        navigate("/payment/status?order_id=" + data.order_id);
        return;
      }
      if (data?.juspay?.payment_url?.web) {
        window.location.href = data?.juspay?.payment_url?.web;
      }
    }
  };
  useEffect(() => {
   
    if (!state?.coins || !state?.price) {
      navigate("/");
      return;
    }
    
    if (!state.coupon) {

      helper(state.id);
    } else {
      setIsLoading(false);
    }
    setFinalPrice(state.price);
  }, []);

  return (
    <>
      <div className={styles.desktop}>
        <HomePageMetaPixel />
        {/* <Header leftAction={() => { navigate(-1) }} leftIcon hideCrossIcon forceActive logoText="FRND Coins" /> */}
        <div className={styles.screenContainer}>
          <div className={styles.leftPane}>
            <CarouselDesktop images={[image1, image2]} />
            <Benifits textColor={"white"} />
          </div>
          <div className={styles.rightPane}>
            {isLoading ? (
              <Loader />
            ) : (
              <div className={styles.container}>
                <div className={styles.heroContainer}>
                  <video loop style={{ width: "100vw" }} muted autoPlay>
                    <source src={HERO_PAYMENT_VIDEO} type="video/mp4" />
                  </video>
                  <img src={safety} className={styles.safetyImage} />
                </div>
                <div
                  className={`${
                    UPI_OPTIONS.length > 0 ? styles.content : styles.contentBig
                  }`}
                >
                  <div className={styles.headingContainer}>
                    <div className={styles.packageLabel}>Your Package</div>
                    <div
                      onClick={() => navigate(-1)}
                      className={styles.changePackage}
                    >
                      Change
                    </div>
                  </div>
                  <div className={styles.billContainer}>
                    <div className={styles.row}>
                      <div className={styles.coinsLabel}>
                        {state.coins} Coins
                      </div>
                      <div className={styles.coinsValue}>
                        ₹{state.original_price || state.price}
                      </div>
                    </div>
                    {state.website_discount > 0 && (
                      <div className={`${styles.row} ${styles.rowHighlight}`}>
                        <div className={styles.couponsLabel}>
                          Website Discount
                        </div>
                        <div className={styles.couponsValue}>
                          -₹{state.website_discount}
                        </div>
                      </div>
                    )}
                    {discount > 0 && (
                      <div className={styles.row}>
                        <div className={styles.couponsLabel}>
                          Coupon Discount
                        </div>
                        <div className={styles.couponsValue}>-₹{discount}</div>
                      </div>
                    )}
                    <div className={styles.rowWithDivider}>
                      <div className={styles.finalLabel}>Final Amount</div>
                      <div className={styles.finalValue}>₹{finalPrice}</div>
                    </div>
                  </div>
                  <CouponApplier
                    packageId={packageId}
                    onApplySuccess={({
                      discount_price,
                      discount,
                      discount_package_id,
                      coupon_id,
                    }) => {
                      setFinalPrice(discount_price);
                      setDiscount(discount);
                      setSelectedCoupon({
                        discount_package_id,
                        coupon_id,
                      });
                    }}
                    onClearCoupon={() => {
                      setDiscount(0);
                      setFinalPrice(state.price);
                      setSelectedCoupon(null);
                    }}
                    initialCoupon={state.coupon || ""}
                    style={{
                      margin: "16px 0",
                    }}
                  />
                  <Button
                    onPress={handleOrderPlacement}
                    shimmer
                    className={`${styles.desktopButton} ${styles.button}`}
                    buttonText={"Add coins now"}
                    backgroundColor={"#1BB785"}
                  />
                </div>
                <div className={styles.actionCenter}>
                  {UPI_OPTIONS.length > 0 && (
                    <div className={styles.selectedPaymentContainer}>
                      <div className={`${styles.iconContainer}`}>
                        {showPaymentOptions ? null : (
                          <div
                            className={`${styles.upiOption} ${styles.selectedOption}`}
                          >
                            <img
                              src={successIcon}
                              className={styles.selectedIcon}
                            />
                            <img
                              src={UPI_DATA_MAP[selectedUpiOption].icon}
                              className={styles.paymentIcon}
                            />
                          </div>
                        )}
                        <div className={styles.paymentOptionText}>
                          {showPaymentOptions
                            ? "Select UPI"
                            : UPI_DATA_MAP[selectedUpiOption].displayName}
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          if (UPI_OPTIONS.length > 1) {
                            setShowPaymentOptions(!showPaymentOptions);
                          } else {
                            handleOrderPlacement(true);
                          }
                        }}
                        className={`${styles.iconContainer}`}
                      >
                        {showPaymentOptions ? null : (
                          <div className={styles.morePaymentOptionText}>
                            {UPI_OPTIONS.length > 1
                              ? "More UPI"
                              : "Change Payment Mode"}
                          </div>
                        )}
                        <img
                          src={back}
                          className={`${
                            showPaymentOptions
                              ? styles.morePaymentsIconRotate
                              : null
                          } ${styles.morePaymentOptionIcon}`}
                          alt="more upi options icon"
                        />
                      </div>
                    </div>
                  )}
                  <div
                    className={`${
                      showPaymentOptions
                        ? null
                        : styles.hiddenupiSelectorContainer
                    } ${styles.upiSelectorContainer}`}
                  >
                    <div className={styles.upiOptions}>
                      {UPI_OPTIONS.map((key) => {
                        const option = UPI_DATA_MAP[key];
                        if (!option) return false;
                        return (
                          <div
                            onClick={() => {
                              setSelectedUpiOption(key);
                              setShowPaymentOptions(false);
                            }}
                            className={styles.largePaymentOptionContainer}
                          >
                            <div
                              className={`${styles.upiOption} ${
                                styles.upiOptionLarge
                              } ${
                                key === selectedUpiOption &&
                                styles.selectedOption
                              }`}
                            >
                              {key === selectedUpiOption && (
                                <img
                                  src={successIcon}
                                  className={styles.selectedIcon}
                                />
                              )}
                              <img
                                src={option.icon}
                                className={styles.paymentIcon}
                              />
                            </div>
                            <div>{option.displayName}</div>
                          </div>
                        );
                      })}
                    </div>
                    <div
                      onClick={() => handleOrderPlacement(true)}
                      className={styles.morePackagesButton}
                    >
                      Change Payment Mode
                    </div>
                  </div>
                  <div className={styles.selectorContainer}>
                    <Button
                      onPress={handleOrderPlacement}
                      shimmer
                      className={styles.button}
                      buttonText={"Add coins now"}
                      backgroundColor={"#1BB785"}
                    />
                    <div
                      onClick={() => navigate("/packages")}
                      className={styles.morePackagesButton}
                    >
                      More Packages
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
